body {
  @apply antialiased text-gray-900 h-full;
}

@font-face {
  font-family: 'Everett';
  src: url('./Everett-Medium.otf') format('otf');
}

.text-input-field {
  @apply w-full px-1 pt-1 mb-2 border-b border-black;
}
.text-input-field:focus {
  @apply outline-none bg-primary-200;
}

:root {
  --h1-font-size: 4.5rem;
  --h2-font-size: 4rem;
  --h3-font-size: 2.5rem;
  --h4-font-size: 2.25rem;
  --h5-font-size: 1.75rem;
  --h6-font-size: 1.5rem;
  --h1-mobile-font-size: calc(var(--h1-font-size) * 0.5);
  --h2-mobile-font-size: calc(var(--h2-font-size) * 0.5);
  --h3-mobile-font-size: calc(var(--h3-font-size) * 0.5);
  --h4-mobile-font-size: calc(var(--h4-font-size) * 0.5);
  --h5-mobile-font-size: calc(var(--h5-font-size) * 0.5);
  --h6-mobile-font-size: calc(var(--h6-font-size) * 0.5);
  --h1-tablet-font-size: calc(var(--h1-font-size) * 0.75);
  --h2-tablet-font-size: calc(var(--h2-font-size) * 0.75);
  --h3-tablet-font-size: calc(var(--h3-font-size) * 0.75);
  --h4-tablet-font-size: calc(var(--h4-font-size) * 0.75);
  --h5-tablet-font-size: calc(var(--h5-font-size) * 0.75);
  --h6-tablet-font-size: calc(var(--h6-font-size) * 0.75);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-medium font-headline;
}

h1 {
  font-size: var(--h1-mobile-font-size);
  line-height: calc(var(--h1-mobile-font-size) * 1.2);
  letter-spacing: -0.02em;
}

h2 {
  font-size: var(--h2-mobile-font-size);
  line-height: calc(var(--h2-mobile-font-size) * 1.2);
  letter-spacing: -0.01em;
  font-weight: 400;
}

h3 {
  font-size: var(--h3-mobile-font-size);
  line-height: calc(var(--h3-mobile-font-size) * 1.3);
  letter-spacing: 0;
}

h4 {
  font-size: var(--h4-mobile-font-size);
  line-height: calc(var(--h4-mobile-font-size) * 1.2);
  letter-spacing: 0;
  font-weight: 400;
}

h5 {
  font-size: var(--h5-mobile-font-size);
  line-height: calc(var(--h5-mobile-font-size) * 1.2);
  letter-spacing: 0;
  font-weight: 400;
}

h6 {
  font-size: var(--h6-mobile-font-size);
  line-height: calc(var(--h6-mobile-font-size) * 1.2);
  letter-spacing: 0;
  font-weight: 400;
}

@screen sm {
  .text-input-field {
    @apply w-72;
  }

  h1 {
    font-size: var(--h1-tablet-font-size);
    line-height: calc(var(--h1-tablet-font-size) * 1.2);
    letter-spacing: -0.02em;
  }

  h2 {
    font-size: var(--h2-tablet-font-size);
    line-height: calc(var(--h2-tablet-font-size) * 1.2);
    letter-spacing: -0.01em;
    font-weight: 400;
  }

  h3 {
    font-size: var(--h3-tablet-font-size);
    line-height: calc(var(--h3-tablet-font-size) * 1.3);
    letter-spacing: 0;
  }

  h4 {
    font-size: var(--h4-tablet-font-size);
    line-height: calc(var(--h4-tablet-font-size) * 1.2);
    letter-spacing: 0;
    font-weight: 400;
  }

  h5 {
    font-size: var(--h5-tablet-font-size);
    line-height: calc(var(--h5-tablet-font-size) * 1.2);
    letter-spacing: 0;
    font-weight: 400;
  }

  h6 {
    font-size: var(--h6-tablet-font-size);
    line-height: calc(var(--h6-tablet-font-size) * 1.2);
    letter-spacing: 0;
    font-weight: 400;
  }
}

@screen md {
  .text-input-field {
    @apply w-80;
  }

  h1 {
    font-size: var(--h1-font-size);
    line-height: calc(var(--h1-font-size) * 1.2);
    letter-spacing: -0.02em;
  }

  h2 {
    font-size: var(--h2-font-size);
    line-height: calc(var(--h2-font-size) * 1.2);
    letter-spacing: -0.01em;
    font-weight: 400;
  }

  h3 {
    font-size: var(--h3-font-size);
    line-height: calc(var(--h3-font-size) * 1.3);
    letter-spacing: 0;
  }

  h4 {
    font-size: var(--h4-font-size);
    line-height: calc(var(--h4-font-size) * 1.2);
    letter-spacing: 0;
    font-weight: 400;
  }

  h5 {
    font-size: var(--h5-font-size);
    line-height: calc(var(--h5-font-size) * 1.2);
    letter-spacing: 0;
    font-weight: 400;
  }

  h6 {
    font-size: var(--h6-font-size);
    line-height: calc(var(--h6-font-size) * 1.2);
    letter-spacing: 0;
    font-weight: 400;
  }
}

@screen lg {
  .text-input-field {
    @apply w-96;
  }
}
