@tailwind base;
@tailwind components;
@tailwind utilities;
@import './identity-utilities.scss';
@import 'react-phone-input-2/lib/style.css';

Html {
  @apply antialiased;
  @apply leading-tight;
  @apply text-black;
  @apply font-body;
  @apply h-full;
}

#root {
  @apply flex flex-col h-full;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  @apply appearance-none;
  @apply m-0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

div.tooltip.show {
  background-color: rgb(67, 126, 132);
  @apply opacity-100;
  @apply p-3;
}

div.tooltip.show::after {
  @apply border-0;
}

.whiteSecondaryGradient {
  background: linear-gradient(180deg, #ffffff 0%, #ebefff 30.21%, #ebefff 100%);
}

.highcharts-point {
  stroke-width: 1;
  stroke: rgb(0, 0, 0);
}

.popup-overlay {
  background-color: rgba(112, 112, 112, 0.7);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}

body:after {
  content: 'beta';
  position: fixed;
  width: 130px;
  height: 30px;
  background: #2f354f;
  top: 20px;
  right: -30px;
  text-align: center;
  font-size: 20px;
  font-family: sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: #fff;
  line-height: 27px;
  transform: rotate(45deg);
  z-index: 100;
}

.react-tel-input input.form-control {
  @apply border-0 pl-12 text-xs h-6 wh3:h-8 wh4:h-8 wh5:h-12 wh5:text-base wh3:text-sm w-full px-2 font-normal mt-0 rounded wh4:rounded-lg bg-white focus:outline-none font-arial focus:ring-1 focus:ring-secondary-100;
}

.react-tel-input div.flag-dropdown {
  @apply border-0 rounded wh4:rounded-lg bg-white focus:outline-none focus:ring-1 focus:ring-secondary-100;
}

.react-tel-input ul.country-list {
  @apply z-50 rounded-lg;
}
